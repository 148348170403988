import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import {graphql, useStaticQuery} from 'gatsby'
import SEO from '../components/seo'
import Link from "../components/Link";

const Blog=()=> {
    
    const data=useStaticQuery(graphql`
    query MyQuery6 {
        allStrapiBlogs(sort: {fields: order}) {
          edges {
            node {
              id
              image {
                publicURL
              }
              description
              order
              link
            }
          }
        }
      }      
    `)
    return (
        <StyledWrapper>
            <Layout>
                <SEO title="Blog" path="/blog"/>
                <div className="blog">
                    <div className="blog__main">
                        <div className="page__title">
                            Blog
                        </div>
                        <div className="blog__content">
                            <div className="row">
                                {data.allStrapiBlogs.edges.map(doc=>(
                                    <Link to={doc.node.link} target="_blank"className="col-md-6 col-sm-8 blog__item" >
                                        <img className="blog__image" src={doc.node.image.publicURL} alt=""/> 
                                        <h4 className="blog__title mt-2">{doc.node.description}</h4>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </StyledWrapper>
    )
}
export default Blog

const StyledWrapper = styled.div`
.blog{
    transition:750ms;
    height:100%;
}
.blog__main{
    padding:150px 100px 100px 100px;
}
.blog__item{
    padding:0px 96px 50px 15px;
    color:black;
    text-decoration:none;
}
.blog__item > h4{
    color:black;
}
.blog__image{
    max-width: 100%;
    max-height: 100%;
    height: 400px;
    width: 800px;
    object-fit: cover;
    cursor:pointer;
    transition: transform .25s, filter .25s ease-in-out;
    width:100%;
    overflow:hidden; 
    &:hover{
        transform: scale(1.03);
    }
}
  .blog__title {
    letter-spacing: 0px;
  }
  
  
a::before{
    display:none !important;
}
@media(max-width:992px){
    .blog{
        border-left:none;
    }
    .blog__main{
        padding-left:20px;
        padding-right:20px;
    }
    .blog__item{
        padding:0px 10px 24px 15px;
    }
    .blog__desc{
        margin-top:20px;
        margin-bottom:100px;
    }
}
`